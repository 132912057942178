.pages_layout {
  min-height: calc(100vh + 510px);
}

.installButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
